@font-face {
  font-family: 'Namotura';
  src: url(/src/fonts/NamotaruFont.otf);
}

@font-face {
  font-family: 'Space-Grotesk';
  src: url(/src/fonts/SpaceGrotesk-VariableFont.ttf);
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 5px #fff;
  }
  50% {
    transform: scale(1.1);
    box-shadow: 0 0 20px #fff;
  }
  100% {
    transform: scale(1);
    box-shadow: 0 0 5px #fff;
  }
}

.pulse-glow {
  animation: pulse 2s infinite;
}

.blog-title {
  font-size: 3rem !important;
}


.white-bg {
  background-color: #ffffff; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  animation: subtleGlow 3s ease-in-out infinite alternate; 
  border-radius: 8px; 
  transition: box-shadow 0.3s ease; 
}

.white-bg:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@keyframes subtleGlow {
  0% {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  }
}
